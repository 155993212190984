
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive
} from 'vue'
import {
  aturUlangPassword
} from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers,
  email,
  sameAs,
  minLength
} from '@vuelidate/validators'
import {
  useStore
} from 'vuex'
import {
  useRoute,
  useRouter
} from 'vue-router'

const mustBeCool = (value: any) => {
  /* eslint-disable no-useless-escape */
  const regSpecialChar = new RegExp(/(?=.[!@#\$%^&*])/)
  return regSpecialChar.test(value)
}

const regatLeast1Numeric = (value: any) => {
  /* eslint-disable no-useless-escape */
  const regSpecialChar = new RegExp(/(?=.[0-9])/)
  return regSpecialChar.test(value)
}

export default {
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()
    const form = reactive({
      password: null
    })
    const submitted = ref(false)
    const title = ref('Reset Password')
    const titleButton = ref('Submit')
    const rules = {
      password: {
        required: helpers.withMessage('Password Baru harus diisi', required),
        // specialChar: helpers.withMessage('Password Baru harus menggunakan karakter yang spesial', helpers.regex(/(?=.[!@#\$%^&*])/)),
        specialChar: helpers.withMessage('Password Baru harus ada spesial karakter (!@#\$%^&*)', mustBeCool),
        regexLeast1Numeric: helpers.withMessage('Password Baru harus ada angka', regatLeast1Numeric),
        minLength: helpers.withMessage(
          (ctx: any) => `Password Baru minimal mengandung ${ctx.$params.min} karakter`,
          minLength(8)
        )
      },
    }

    const v$ = useVuelidate(rules, form)

    const checkResponse = (val: any) => {
      console.log('pass', val)
      if (val.error) {
        $toast.add({
          severity: 'error',
          detail: val.message,
          group: 'bc',
          life: 5000
        })
      } else {
        $toast.add({
          severity: 'success',
          detail: val.result.detail ?? val.result.Detail,
          group: 'bc',
          life: 5000
        })
      }
      store.dispatch('hideLoading')
    }

    const storeProcess = async () => {
      store.dispatch('showLoading')
      const data = {
        Password: form.password,
        Hash: route.params.hashEmail
      }
      const response = await aturUlangPassword(data)
      checkResponse(response)
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      storeProcess()
    }
    return {
      form,
      storeProcess,
      submitted,
      submitData,
      v$,
      router,
      title,
      titleButton
    }
  }
}
